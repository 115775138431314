import { styles } from "@sdir/sds";
import styled from "styled-components";
import {ChevronRightIcon} from "@navikt/aksel-icons";

interface TagProps {
    children: any;
    type?: "secondary" | "primary";
    onClick?: any;
}

const TagComponent = ({ children, type, onClick }: TagProps) => {
    return (
        <Button onClick={(event : any) => onClick(event)} className={type}>
            {children}
            <ButtonIcon>
                <ChevronRightIcon />
            </ButtonIcon>
        </Button>
    )
}

const Button = styled.button`
    align-items: center;
    border: none;
    cursor: pointer;
    display: inline-flex;
    padding: 2px 2rem 1px;
    border-radius: 2px;
    background-color: #D9E2E9;
    font-size: 1.75rem;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.5;
    color: ${styles.colors.primary};
    font-family: ${styles.fonts.stfMedium};
    letter-spacing: .05em;
    &.secondary {
        background-color: ${styles.colors.background.white};
    }
    &:hover {
        outline: 0.25rem solid ${styles.colors.primary5};
    }
  
    @media print {
      background-color: transparent;
      color: #000000;
      padding: 0;
      text-transform: none;
      
      &::before {
        content: '(';
      }
      
      &::after {
        content: ')';
      }
    }
`

const ButtonIcon = styled.span`
    font-size: 2.5rem;
    line-height: 1;
    height: 2.5rem;
    margin-top: -1px;
  
    @media print {
        display: none;
    }
`

export const Tag = TagComponent;