import {styles} from "@sdir/sds"
import styled from "styled-components"
import {getTranslations} from "../../helpers/Helpers";


interface DocumentGroupProps {
    title?: string;
    children?: React.ReactNode;
}

const DocumentGroupComponent = ({title, children}: DocumentGroupProps) => {
    const recordLang = getTranslations().record;
    
    return (
        <Wrapper>
            {title &&
                <Header>
                    <h2>{title}</h2>
                    <LabelRow aria-hidden={true}>
                        <span>{recordLang.journalNumber}</span>
                        <span>{recordLang.date}</span>
                    </LabelRow>
                </Header>
            }
            <Body>
                {children}
            </Body>
        </Wrapper>
    )
}

const Wrapper = styled.article`
    background-color: ${styles.colors.background.white};
    border-bottom: 1px solid ${styles.colors.border};
    border-top: 1px solid ${styles.colors.border};
    margin-bottom: 8rem;
    margin-left: -2rem;
    width: calc(100% + 4rem);

    @media (min-width: 800px) {
        border: 1px solid ${styles.colors.border};
        border-radius: ${styles.card.borderRadius};
        margin-bottom: 9rem;
        margin-left: 0;
        width: 100%;
    }

    @media print {
        background-color: transparent;
        border: none;
        border-radius: 0;
        border-top: 2px solid #000000;
        margin-bottom: 0;
        margin-left: 0;
        width: 100%;

        &:last-child {
            border-bottom: 2px solid #000000;
        }
    }
`

const Header = styled.header`
    background-color: ${styles.colors.primary10};
    scroll-margin-top: 4rem;

    h2 {
        margin: 0;
        font-family: ${styles.fonts.stfMedium};
        font-size: 3rem;
        line-height: 1.5;
        padding: 1.5rem 2rem;

        @media screen and (min-width: 800px) {
            padding-inline: 3rem;
        }
    }

    @media print {
        background-color: transparent;
        border-bottom: 1px solid #000000;
        padding-block: 0;

        h2 {
            padding: 1rem 0;
        }
    }
`

const LabelRow = styled.div`
    display: none;

    @media screen and (min-width: 800px) {
        display: flex;
        border-top: 1px solid ${styles.colors.border};
        padding: 1rem 3rem;
    }

    @media print {
        display: flex;
        padding-bottom: 1rem;
    }

    span {
        color: ${styles.colors.primary};
        font-family: ${styles.fonts.stfMedium};
        font-size: 2rem;
        line-height: 1.5;

        &:first-child {
            width: 13rem;
        }

        @media print {
            color: #000000;
        }
    }
`

const Body = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`

export const DocumentGroup = DocumentGroupComponent;