import {Grid, GridColumn, LooseObject} from "./VesselPage";
import {AnchorMenu} from "ui/AnchorMenu";
import {SrDocument} from "../../models/Models";
import {VesselDocument} from "../../ui/VesselDocument/VesselDocument";
import {useRef} from "react";
import {getTranslations} from "../../helpers/Helpers";
import {DocumentGroup} from "../../ui/DocumentGroup/DocumentGroup";

interface PresentRecordProps {
    documents: SrDocument[];
    handleNavigateToHistoricalJournal?: (event : any, anchorKey : string) => void;
}

// Norwegian and english names of documents, ordered according to SDIR
const documentOrder : string[] = [
    // Etablering - Establishment
    "MELDING",
    "NOTIFICATION TO THE REGISTER",
    "TONNAGE CERTIFICATE",
    
    // Teknisk - Technical
    "MÅLEBREV",
    "NYTT NASJONALITETSBEVIS",
    "NEW CERTIFICATE OF NATIONALITY",
    "CONTINUOUS SYNOPSIS RECORD",
    
    // Hjemmel - Ownership
    "BYGGEBREV",
    "CONSTRUCTION CERTIFICATE",
    "HJEMMELSDOKUMENT",
    "DOCUMENT OF OWNERSHIP",
    "SKJØTE",
    "BILL OF SALE",
    "NY ISM-ANSVARLIG",
    "NEW ISM-LIABLE",
    
    // Heftelser - Encumberance
    "OBLIGASJON",
    "MORTGAGE",
]

interface GroupedDocuments {
    etablering: SrDocument[];
    teknisk: SrDocument[];
    heftelser: SrDocument[];
    hjemmel: SrDocument[];
}



const compareDocumentsOrder = function (a : string, b : string) {
    let orderA = documentOrder.indexOf(a) + 1 || 999; // Not found in list -> (-1 + 1) = 0 -> 999 -> last in order
    let orderB = documentOrder.indexOf(b) + 1 || 999;
    return orderA < orderB ? -1 : 1;
}

const formatPresentDocumentGroup = function (documents : SrDocument[]) {
    const regularDocuments = documents
        .filter(document => !document.endorsedByJournalKey)
        .sort((a, b) => compareDocumentsOrder(a.documentType, b.documentType));

    const endorsementDocuments = documents
        .filter(document => document.endorsedByJournalKey)
        .sort((a, b) => new Date(a.journalDate) > new Date(b.journalDate) ? -1 : 1); //Reverse sort by date

    endorsementDocuments.forEach(endorsementDocument => {
        const documentBeingEndorsed = regularDocuments
            .find(document => {
                return document.journalKey.slice(4) === endorsementDocument.endorsedByJournalKey?.split("/")[0]
            });
        if (!documentBeingEndorsed)
            return;

        const documentBeingEndorsedIndex = regularDocuments.indexOf(documentBeingEndorsed);
        regularDocuments.splice(documentBeingEndorsedIndex + 1, 0, endorsementDocument);
    });
    return regularDocuments;
}

const PresentRecordComponent = ({documents, handleNavigateToHistoricalJournal} : PresentRecordProps) => {
    const recordLang = getTranslations().record;

    const anchornames = [
        recordLang.establishment,
        recordLang.technical,
        recordLang.ownership,
        recordLang.encumberance
    ]
    
    const docRefs = useRef<LooseObject>({});

    const groupedDocuments : GroupedDocuments = {
        etablering: formatPresentDocumentGroup(documents.filter(document => document.documentTypeClass === "ET")),
        teknisk: formatPresentDocumentGroup(documents.filter(document => document.documentTypeClass === "TE")),
        hjemmel: formatPresentDocumentGroup(documents.filter(document => document.documentTypeClass === "HJ")),
        heftelser: formatPresentDocumentGroup(documents.filter(document => document.documentTypeClass === "HE"))
    };

    const anchorListItems = []
    groupedDocuments.etablering.length > 0 &&
        anchorListItems.push({name: recordLang.establishment, anchorKey: groupedDocuments.etablering[0].journalKey + "ET"});
    groupedDocuments.teknisk.length > 0 &&
        anchorListItems.push({name: recordLang.technical, anchorKey: groupedDocuments.teknisk[0].journalKey + "TE"});
    groupedDocuments.hjemmel.length > 0 &&
        anchorListItems.push({name: recordLang.ownership, anchorKey: groupedDocuments.hjemmel[0].journalKey + "HJ"});
    groupedDocuments.heftelser.length > 0 &&
        anchorListItems.push({name: recordLang.encumberance, anchorKey: groupedDocuments.heftelser[0].journalKey + "HE"});
    
    return (
        <Grid>
            <GridColumn>
                <AnchorMenu
                    anchorListItems={anchorListItems}
                    docRefs={docRefs}
                />
            </GridColumn>
            <GridColumn>
                {anchornames.map((anchorname, index) =>
                    Object.values(groupedDocuments)[index].length > 0 &&
                    <DocumentGroup title={anchorname} key={anchorname}>
                        {
                            Object.values(groupedDocuments)[index].map((document : SrDocument) =>
                            <li key={document.journalKey + '_' + document.documentType}>
                                <VesselDocument docRefs={docRefs} document={document} handleNavigateToHistoricalJournal={handleNavigateToHistoricalJournal}/>
                            </li>
                            )
                        }
                    </DocumentGroup>
                )}
            </GridColumn>
        </Grid>
    )
}

export const PresentRecord = PresentRecordComponent;