import styled from "styled-components";
import { BackLinkButton } from "ui/BackLinkButton";
import { TitleBanner } from "ui/TitleBanner";

const NotFoundPageComponent = () => {
    return (
        <Main>
            <div className="l-container">
                <BackLinkButton text="Til forsiden" href="/" />
                <TitleBanner title="404 - Denne siden eksisterer ikke" />
                <Description>
                    <p>Dette er trolig en side som ikke eksisterer — eller som tidligere har fantes, men er blitt flyttet.</p>
                    <p>Gå til forsiden for å søke etter et fartøy.</p>
                </Description>
            </div>
        </Main>
    )
}

const Main = styled.main`
    padding-top: 6rem;
    padding-bottom: 12rem;
    `

const Description = styled.div`
    font-size: 2.25rem;

    p {
        margin-top: 0;
    }
`


export const NotFoundPage = NotFoundPageComponent;