import { styles } from "@sdir/sds";
import styled from "styled-components";

interface EmptyStateProps {
    title: string;
    message: string;
}

const EmptyStateComponent = ({title, message}: EmptyStateProps) => {
    return (
        <Container>
            <h2>{title}</h2>
            <p>{message}</p>
        </Container>
    )
}

const Container = styled.div`
    margin-top: 6rem;
    text-align: center;
    padding: 4rem;
    font-size: 2.25rem;
    color: ${styles.colors.primary2};
    position: relative;

    & > * {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
    
    h2 {
        font-size: 2.5rem;
        text-align: center;
        font-family: ${styles.fonts.stfMedium};
        margin-block: 0;
        line-height: 1.25;
    }

    p {
        margin-block: 0;
        margin-top: .25em;
    }
`

export const EmptyState = EmptyStateComponent;