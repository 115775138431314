import { ArrowsUpDownIcon } from "@navikt/aksel-icons"
import { styles } from "@sdir/sds"
import styled from "styled-components"
import {getTranslations} from "../../helpers/Helpers";

export interface AnchorListItem {
    name: string;
    anchorKey: string;
}

interface AnchorMenuProps {
    anchorListItems: AnchorListItem[];
    docRefs?: any;
}

const AnchorMenuComponent = ({ anchorListItems, docRefs }: AnchorMenuProps) => {
    const anchorMenuLang = getTranslations().anchorMenu;
    
    return (
        <Wrapper>
            <h2 id="anchorMenuTitle">{anchorMenuLang.jumpTo}</h2>
            <ol aria-labelledby="anchorMenuTitle">
                {anchorListItems.map((anchorListItem) => (
                    <li key={anchorListItem.anchorKey}>
                        <button onClick={(e) => {
                            e.preventDefault();
                            window.scrollTo({
                                top: (docRefs.current[anchorListItem.anchorKey].offsetTop - 160),
                                behavior: "smooth"
                            });
                        }
                        }>
                            <span>{anchorListItem.name}</span>
                            <ArrowsUpDownIcon/>
                        </button>
                    </li>
                ))}
            </ol>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    position: sticky;
    top: 4rem;
    max-height: calc(100dvh - 4rem);
    overflow-y: auto;
    h2 {
        font-size: 2rem;
        font-family: ${styles.fonts.stfMedium};
        margin-top: 0;
    }

    ol {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        border-bottom: 1px solid ${styles.colors.border};
        &:first-child {
            border-top: 1px solid ${styles.colors.border};
        }
    }

    button {
        align-items: center;
        background-color: transparent;
        border: none;
        color: ${styles.colors.primary2};
        cursor: pointer;
        display: flex;
        font-size: 2rem;
        gap: 2rem;
        justify-content: space-between;
        line-height: 3rem;
        padding: 1.5rem .5rem;
        width: 100%;
        
        svg {
            opacity: 0;
            font-size: 1.25em;
            color: ${styles.colors.primary2};
            flex-shrink: 0;
        }

        &:hover {
            svg {
                opacity: 1;
            }
        }
    }
  
    @media print {
      display: none;
    }
`

export const AnchorMenu = AnchorMenuComponent;