import { ArrowLeftIcon } from "@navikt/aksel-icons"
import { styles } from "@sdir/sds"
import styled from "styled-components"

interface BackLinkButtonProps {
    text: string;
    href?: string
}

const BackLinkButtonComponent = ({ text, href }: BackLinkButtonProps) => {
    
    return (
        <LinkButton href={href} >
            <ArrowLeftIcon />
            <span>
                { text }
            </span>
        </LinkButton>
    )
}

const LinkButton = styled.a`
    color: ${styles.colors.primary2};
    cursor: pointer;
    margin-bottom: 4rem;
    display: inline-flex;
    align-items: center;
    gap: .5rem;
    padding: .25em .5em;
    border-radius: .5rem;
    margin-left: -.5em;
    transition: background-color .15s ease;
    svg {
        flex-shrink: 0;
        color: currentColor;
        font-size: 1.25em;
    }

    &:hover {
        background-color: ${styles.colors.primary10};
    }

    @media print {
        display: none;
    }
`

export const BackLinkButton = BackLinkButtonComponent;