import styled from "styled-components";
import { styles } from "@sdir/sds";
import {MetadataList, MetadataListType} from "ui/MetadataList";
import { BackLinkButton } from "ui/BackLinkButton";
import { PresentRecord } from "./PresentRecord";
import { HistoricalRecord } from "./HistoricalRecord";
import { TitleBanner } from "ui/TitleBanner";
import {useEffect, useRef, useState} from "react";
import {getHistoricalVessel, getVessel} from "../../services/APIService";
import {VesselDTO} from "../../models/Models";
import {useSearchParams} from "react-router-dom";
import {Spinner, SpinnerInner} from "./StartPage";
import {getLanguageFromURL, getTranslations} from "../../helpers/Helpers";

export interface LooseObject {
    [key: string]: any
}

const VesselPageComponent = () => {
    const vesselPageLang = getTranslations().vesselPage;
    
    const [searchParams, _] = useSearchParams();
    const historicalRefs = useRef<LooseObject>({});
    const [isLoading, setIsLoading] = useState(false)
    const [metaData, setMetaData] = useState<MetadataListType[]>([]);
    const [currentHistoricalDocument, setCurrentHistoricalDocument] = useState("");
    const [currentTab, setCurrentTab] = useState(0);
    const [presentVessel, setPresentVessel] = useState<VesselDTO>();
    const [searchString, setSearchString] = useState<string>("");
    const [register, setRegister] = useState<string>("");

    const [historicalVessel, setHistoricalVessel] = useState<VesselDTO>();
    
    useEffect(() => {
        const vesselId = searchParams.get('vesselId');
        const searchString = searchParams.get('search') ?? "";
        const register = searchParams.get('register') ?? "";
        
        if (!vesselId || !register)
            return;
        
        setIsLoading(true);
        
        getVessel(vesselId)
            .then((result) =>
            {
                setSearchString(searchString);
                setRegister(register);
                setPresentVessel(result);
                
                let metaData = [
                    {
                        label: register === "nisnor" ? vesselPageLang.vesselName : vesselPageLang.constructionNumber,
                        value: register === "nisnor" ? result?.name : result?.documents.filter(document => document.construction?.constructionNumber)[0]?.construction?.constructionNumber,
                    },
                    {
                        label: register === "nisnor" ? vesselPageLang.callSign : vesselPageLang.shipyard,
                        value: register === "nisnor" ? result?.callSign : result?.documents.filter(document => document.construction?.shipyard)[0]?.construction?.shipyard
                    },
                    {
                        label: vesselPageLang.register,
                        value: result?.register
                    },
                    {
                        label: vesselPageLang.imoNumber,
                        value: result?.imo
                    },
                    {
                        label: "Status",
                        value: result?.status
                    }
                ]
                
                if (result?.bareboatStatus && result?.bareboatStatus !== "Ikke bareboat") {
                    metaData.push({
                        label: "Bareboat status",
                        value: result?.bareboatStatus
                    });
                }
                
                setMetaData(metaData)
                setIsLoading(false);
            });

        getHistoricalVessel(vesselId)
            .then((result) => setHistoricalVessel(result));
    }, [])
    
    const handleNavigateToHistoricalJournal = function (event : any, anchorKey : string) {
        event.preventDefault();
        setCurrentTab(1);
        setCurrentHistoricalDocument(anchorKey);
    }
    
    return (
        <Main>
            <div className="l-container">
                {isLoading &&
                    <Spinner>
                        <SpinnerInner />
                    </Spinner>
                }
                {presentVessel &&
                <>
                    <BackLinkButton text={vesselPageLang.backToSearch} href={`#${getLanguageFromURL() === 'en' ? '/en' : ''}/?search=${searchString}&register=${register}`}/>
                    <TitleBanner title={`${vesselPageLang.vessel} ${register === "nisnor" ? (presentVessel.name ?? "n/a") : (presentVessel?.documents.filter(document => document.construction?.constructionNumber)[0]?.construction?.constructionNumber ?? "n/a")} — ${register === "nisnor" ? (presentVessel.callSign ?? "n/a") : (presentVessel?.documents.filter(document => document.construction?.shipyard)[0]?.construction?.shipyard ?? "n/a")}`} />
                    <MetadataList list={metaData} />
                    
                    <TabsWrapper>
                        <Buttons>
                            <Button onClick={() => { setCurrentTab(0); setCurrentHistoricalDocument(""); }} className={currentTab === 0 ? "current" : ""}>{vesselPageLang.presentInfo}</Button>
                            <Button onClick={() => setCurrentTab(1)} className={currentTab === 1 ? "current" : ""}>{vesselPageLang.historicalInfo}</Button>
                        </Buttons>
                        <Content>
                            {currentTab === 0 &&
                                <PresentRecord documents={presentVessel.documents} handleNavigateToHistoricalJournal={handleNavigateToHistoricalJournal}/>}
                            {currentTab === 1 &&
                                (historicalVessel ? (
                                    <HistoricalRecord historicalRefs={historicalRefs} currentHistoricalDocument={currentHistoricalDocument} documents={historicalVessel.documents}/>
                                ) : (
                                    <Spinner>
                                        <SpinnerInner />
                                    </Spinner>
                                ))
                            }
                        </Content>
                    </TabsWrapper>
                </>}
            </div>
        </Main>
    )
}

const Main = styled.main`
    padding-top: 6rem;
    padding-bottom: 12rem;
  
    @media print {
      padding: 0;
    }
`

export const Grid = styled.div`
    display: grid;
    gap: 6rem;
    grid-template-columns: 1fr;
    padding-top: 4rem;
    @media screen and (min-width: 1024px) {
        grid-template-columns: 19rem auto;
        margin-top: 4rem;
        gap: 9rem;
    }
    
    @media print {
      display: block;
      padding-top: 0;
    }
`

export const GridColumn = styled.div`
    container-name: grid-column;
    container-type: inline-size;
`

const TabsWrapper = styled.div`
    margin-top: 4rem;
  
    @media (min-width: 1300px) {
        width: calc(100% + 2rem);
        margin-left: -1rem;
    }
`

const Buttons = styled.div`
    display: flex;
    gap: .5rem;
    border-radius: .75rem;
    background-color: ${styles.colors.background.secondary};
    padding: 1rem;
    border: 1px solid ${styles.colors.border};
    @media (max-width: 1300px) {
        padding: .75rem;
        width: calc(100% + 1.5rem);
        margin-left: -.75rem;
        justify-content: stretch;
    }

  @media print {
    display: none;
  }
`

const Button = styled.button`
    border: 0;
    background-color: transparent;
    border-radius: .5rem;
    color: ${styles.colors.font.text};
    cursor: pointer;
    font: inherit;
    padding: 1.25rem 1rem;
    width: 100%;
    @media (min-width: 1300px) {
        flex-shrink: 0;
        width: auto;
        padding: 1.5rem 3rem;
    }
    
    &.current {
        color: ${styles.colors.font.white};
        background-color: ${styles.colors.primary2};
    }
    
    &:not(.current):hover {
        background-color: ${styles.colors.primary10};
        color: ${styles.colors.font.text};
    }
`

const Content = styled.div`
    @media (min-width: 1300px) {
        padding-inline: 1rem;
    }
`

export const VesselPage = VesselPageComponent;