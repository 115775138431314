import {styles} from "@sdir/sds";
import styled from "styled-components";
import {Document} from "../Document/Document";
import {Tag} from "../Tag";
import {SrDocument} from "../../models/Models";
import {LooseObject} from "../../routes/pages";
import {
    formatDate,
    getTranslations,
    formatNumber
} from "../../helpers/Helpers";

interface VesselDocumentProps {
    document: SrDocument;
    docRefs?: any;
    handleNavigateToHistoricalJournal?: (event: any, anchorKey: string) => void;
}

const VesselDocumentComponent = ({document, docRefs, handleNavigateToHistoricalJournal}: VesselDocumentProps) => {
    const docLang = getTranslations().vesselDocument;

    if (document.updatedValues) {
        document.updatedValues.forEach(updatedValue => {
            (document as LooseObject)[updatedValue.key] = updatedValue.value;
        })
    }

    const getUpdatedKey = function (key: string) {
        return document.updatedValues?.find(updatedValue => updatedValue.key === key);
    }

    return (
        <Document key={document.journalKey} journalNumber={document.journalKey.slice(4)}
                  date={formatDate(document.journalDate)}
                  title={document.documentType + ((document.documentType === "BILL OF SALE" || document.documentType === "SKJØTE")
                  && document.currency && document.amount
                      ? `: ${document.currency ?? ""} ${formatNumber(document.amount, 2)}` : "")}
                  isEndorsement={!!document.endorsedByJournalKey}>
            <DocumentBody ref={(el: any) => {
                if (docRefs) docRefs.current[document.journalKey + document.documentTypeClass] = el;
            }}>
                {document.date &&
                    <DocumentRowComponent label={docLang.date} value={formatDate(document.date)}/>
                }
                {document.voluntaryRegistration === false && document.register !== "Norwegian International Ship Register" && document.register !== "Norsk Internasjonalt Skipsregister" && document.register !== "NIS" &&
                    (document.documentType === "MELDING" || document.documentType === "MELDING DOKUMENTERT AV SJØFARTSDIR." ||
                        document.documentType === "REGISTRATION OF MEASURED VESSEL" || document.documentType === "NOTIFICATION TO THE REGISTER") &&
                    <DocumentRowComponent label={docLang.voluntaryRegistration}
                                          value={document.voluntaryRegistration ? docLang.no : docLang.yes}/>
                }
                {document.certificateOfNationalityDate &&
                    <DocumentRowComponent label={docLang.certificateOfNationalityDate}
                                          value={formatDate(document.certificateOfNationalityDate)}/>
                }
                {document.callSignal &&
                    (document.documentType === "MELDING" || document.documentType === "MELDING DOKUMENTERT AV SJØFARTSDIR." ||
                        document.documentType === "REGISTRATION OF MEASURED VESSEL" || document.documentType === "NOTIFICATION TO THE REGISTER") &&
                    <DocumentRowComponent label={docLang.callSignal} value={document.callSignal}/>
                }
                {document.vesselName && (
                    getUpdatedKey("vesselName")
                        ? <DocumentRowComponent
                                label={docLang.vesselName}
                                value={document.vesselName}
                                changedDate={formatDate(getUpdatedKey("vesselName")?.changedDate)}
                                anchorKey={getUpdatedKey("vesselName")?.journalKey + document.documentTypeClass}
                                handleNavigateToHistoricalJournal={handleNavigateToHistoricalJournal}
                                isConferred={getUpdatedKey("vesselName")?.isConferred}/>
                        : <DocumentRowComponent label={docLang.vesselName} value={document.vesselName}/>
                )}
                {document.imo && (document.documentType === "MELDING" || document.documentType === "MELDING DOKUMENTERT AV SJØFARTSDIR." ||
                        document.documentType === "REGISTRATION OF MEASURED VESSEL" || document.documentType === "NOTIFICATION TO THE REGISTER") &&
                    <DocumentRowComponent label={docLang.imoNumber} value={document.imo.toString()}/>
                }
                {document.homePort && (
                    getUpdatedKey("homePort")
                        ? <DocumentRowComponent
                            label={docLang.homePort}
                            value={document.homePort}
                            changedDate={formatDate(getUpdatedKey("homePort")?.changedDate)}
                            anchorKey={getUpdatedKey("homePort")?.journalKey + document.documentTypeClass}
                            handleNavigateToHistoricalJournal={handleNavigateToHistoricalJournal}
                            isConferred={getUpdatedKey("vesselName")?.isConferred}/>
                        : <DocumentRowComponent label={docLang.homePort} value={document.homePort}/>
                )}
                {document.previousVesselName &&
                    <DocumentRowComponent label={docLang.previousVesselName} value={document.previousVesselName}/>
                }
                {document.registrationTerms &&
                    <DocumentRowComponent label={docLang.regConditions} value={document.registrationTerms}/>
                }
                {document.roles?.map(role =>
                    <div key={role.roleType}>
                        <Row>
                            <Term>{role.roleType}</Term>
                            <Description className={"indented"}>{role.legalEntity.name}</Description>
                        </Row>
                        <Sub>
                            {role.legalEntity?.entityId && <DocumentRowComponent
                                label={(role.roleType === "Owner" || role.roleType === "Eier") && role.legalEntity?.entityId && role.legalEntity.entityId.length === 6 ? docLang.birthday : role.legalEntity?.entityId?.startsWith("L-") ? docLang.serialNumber : docLang.orgNumber}
                                value={role.legalEntity.entityId}/>}
                            {role.legalEntity.imo &&
                                <DocumentRowComponent label={docLang.imoNumber}
                                                      value={role.legalEntity.imo.toString()}/>
                            }
                            {role.legalEntity.businessAddress && Object.keys(role.legalEntity.businessAddress).length !== 0 &&
                            role.roleType !== "Beneficiaries" && role.roleType !== "Rettighetshaver" ?
                                <Row>
                                    <Term>{docLang.businessAddress}</Term>
                                    <Description>
                                        {role.legalEntity.businessAddress.streetName?.split("\n").map((substring, index) => (
                                            <span key={index}>{substring}</span>))}
                                        <span>{role.legalEntity.businessAddress.zipCode} {role.legalEntity.businessAddress.city}</span>
                                        {role.legalEntity.businessAddress.country &&
                                            <span>{role.legalEntity.businessAddress.country}</span>
                                        }
                                    </Description>
                                </Row>
                                :
                                role.legalEntity.businessAddress.country && <Row>
                                    <Term>{docLang.nation}</Term>
                                    <Description>
                                        {role.legalEntity.businessAddress.country &&
                                            <span>{role.legalEntity.businessAddress.country}</span>
                                        }
                                    </Description>
                                </Row>
                            }
                            {role.legalEntity.mailingAddress && Object.keys(role.legalEntity.mailingAddress).length !== 0 &&
                                role.roleType !== "Beneficiaries" && role.roleType !== "Rettighetshaver" &&
                                <Row>
                                    <Term>{docLang.mailingAddress}</Term>
                                    <Description>
                                        {role.legalEntity.mailingAddress.streetName?.split("\n").map((substring, index) => (
                                            <span key={index}>{substring}</span>))}
                                        <span>{role.legalEntity.mailingAddress.zipCode} {role.legalEntity.mailingAddress.city}</span>
                                        {role.legalEntity.mailingAddress.country &&
                                            <span>{role.legalEntity.mailingAddress.country}</span>
                                        }
                                    </Description>
                                </Row>
                            }
                        </Sub>
                        {role.coLegalEntity.name &&
                            <>
                                <Row>
                                    <Term>{role.roleType}</Term>
                                    <Description className={"indented"}>{role.coLegalEntity.name}</Description>
                                </Row>
                                <Sub>
                                    {role.coLegalEntity?.entityId && <DocumentRowComponent
                                        label={(role.roleType === "Owner" || role.roleType === "Eier") && role.coLegalEntity?.entityId && role.coLegalEntity.entityId.length === 6 ? docLang.birthday : role.coLegalEntity?.entityId?.startsWith("L-") ? docLang.serialNumber : docLang.orgNumber}
                                        value={role.coLegalEntity.entityId}/>}
                                    {role.coLegalEntity.imo &&
                                        <DocumentRowComponent label={docLang.imoNumber}
                                                              value={role.coLegalEntity.imo.toString()}/>
                                    }
                                    {role.coLegalEntity.businessAddress && Object.keys(role.coLegalEntity.businessAddress).length !== 0 &&
                                        <Row>
                                            <Term>{docLang.businessAddress}</Term>
                                            {role.roleType !== "Beneficiaries" && role.roleType !== "Rettighetshaver" ?
                                                <Description>
                                                    {role.coLegalEntity.businessAddress.streetName?.split("\n").map((substring, index) => (
                                                        <span key={index}>{substring}</span>))}
                                                    <span>{role.coLegalEntity.businessAddress.zipCode} {role.coLegalEntity.businessAddress.city}</span>
                                                    {role.coLegalEntity.businessAddress.country &&
                                                        <span>{role.coLegalEntity.businessAddress.country}</span>
                                                    }
                                                </Description>
                                                :
                                                <Description>
                                                    {role.coLegalEntity.businessAddress.country &&
                                                        <span>{role.coLegalEntity.businessAddress.country}</span>
                                                    }
                                                </Description>
                                            }
                                        </Row>
                                    }
                                    {role.coLegalEntity.mailingAddress && Object.keys(role.coLegalEntity.mailingAddress).length !== 0 &&
                                        <Row>
                                            <Term>{docLang.mailingAddress}</Term>
                                            <Description>
                                                {role.coLegalEntity.mailingAddress.streetName?.split("\n").map((substring, index) => (
                                                    <span key={index}>{substring}</span>))}
                                                <span>{role.coLegalEntity.mailingAddress.zipCode} {role.coLegalEntity.mailingAddress.city}</span>
                                                {role.coLegalEntity.mailingAddress.country &&
                                                    <span>{role.coLegalEntity.mailingAddress.country}</span>
                                                }
                                            </Description>
                                        </Row>
                                    }
                                </Sub>
                            </>}
                    </div>
                )
                }
                {document.bareboat && Object.keys(document.bareboat).length !== 0 &&
                    <><DocumentRowComponent label={docLang.bareboat}/>
                        <Sub>
                            {document.bareboat.mainRegister &&
                                <DocumentRowComponent label={docLang.mainRegister}
                                                      value={document.bareboat.mainRegister}/>
                            }
                            {document.bareboat.bareboatRegister &&
                                <DocumentRowComponent label={docLang.bareboatRegister}
                                                      value={document.bareboat.bareboatRegister}/>
                            }
                            {document.bareboat.mainRegisterVesselName &&
                                <DocumentRowComponent label={docLang.mainRegisterVesselName}
                                                      value={document.bareboat.mainRegisterVesselName}/>
                            }
                            {document.bareboat.bareboatRegisterVesselName &&
                                <DocumentRowComponent label={docLang.bareboatRegisterVesselName}
                                                      value={document.bareboat.bareboatRegisterVesselName}/>
                            }
                            {document.bareboat.mainRegisterCallsign &&
                                <DocumentRowComponent label={docLang.mainRegisterCallsign}
                                                      value={document.bareboat.mainRegisterCallsign}/>
                            }
                            {document.bareboat.bareboatRegisterCallSign &&
                                <DocumentRowComponent label={docLang.bareboatRegisterCallSign}
                                                      value={document.bareboat.bareboatRegisterCallSign}/>
                            }
                            {document.bareboat.bareboatInDateFrom &&
                                <DocumentRowComponent label={docLang.bareboatInDateFrom}
                                                      value={formatDate(document.bareboat.bareboatInDateFrom)}/>
                            }
                            {document.bareboat.bareboatInDateTo &&
                                <DocumentRowComponent label={docLang.bareboatInDateTo}
                                                      value={formatDate(document.bareboat.bareboatInDateTo)}/>
                            }
                            {document.bareboat.bareboatOutDateFrom &&
                                <DocumentRowComponent label={docLang.bareboatOutDateFrom}
                                                      value={formatDate(document.bareboat.bareboatOutDateFrom)}/>
                            }
                            {document.bareboat.bareboatOutDateTo &&
                                <DocumentRowComponent label={docLang.bareboatOutDateTo}
                                                      value={formatDate(document.bareboat.bareboatOutDateTo)}/>
                            }
                        </Sub>
                    </>
                }

                {document.construction && Object.keys(document.construction).length !== 0 &&
                    <><DocumentRowComponent label={docLang.construction}/>
                        <Sub>
                            {document.construction.constructionNumber &&
                                <DocumentRowComponent label={docLang.constructionNumber}
                                                      value={document.construction.constructionNumber}/>
                            }
                            {document.construction.shipyard &&
                                <DocumentRowComponent label={docLang.shipyard} value={document.construction.shipyard}/>
                            }
                            {document.construction.location &&
                                <DocumentRowComponent label={docLang.shipyardLocation}
                                                      value={document.construction.location}/>
                            }
                            {document.construction.start &&
                                <DocumentRowComponent label={docLang.start} value={document.construction.start}/>
                            }
                            {document.construction.end &&
                                <DocumentRowComponent label={docLang.end} value={document.construction.end}/>
                            }
                            {document.construction.constructedFor &&
                                <DocumentRowComponent label={docLang.constructedFor}
                                                      value={document.construction.constructedFor}/>
                            }
                            {document.construction.commissionedBy &&
                                <DocumentRowComponent label={docLang.commissionedBy}
                                                      value={document.construction.commissionedBy}/>
                            }
                            {document.construction.countryDescription &&
                                <DocumentRowComponent label={docLang.shipyardCountry}
                                                      value={document.construction.countryDescription}/>
                            }
                            {document.construction.vesselModel &&
                                <DocumentRowComponent label={docLang.vesselModel}
                                                      value={document.construction.vesselModel}/>
                            }
                            {document.construction.year &&
                                <DocumentRowComponent label={docLang.shipyardYear}
                                                      value={document.construction.year.toString()}/>
                            }
                        </Sub>
                    </>
                }
                {document.srMeasurementData && Object.keys(document.srMeasurementData).length !== 0 &&
                    <>
                        {document.srMeasurementData.measurementCertificateCountry &&
                            <DocumentRowComponent label={docLang.measurementCountry}
                                                  value={document.srMeasurementData.measurementCertificateCountry}/>
                        }
                        {document.srMeasurementData.classificationSociety &&
                            <DocumentRowComponent label={docLang.measurementClassification}
                                                  value={document.srMeasurementData.classificationSociety}/>
                        }
                        {document.srMeasurementData.vesselType &&
                            <DocumentRowComponent label={docLang.measurementVessel}
                                                  value={document.srMeasurementData.vesselType}/>
                        }
                        {document.srMeasurementData.buildMaterial &&
                            <DocumentRowComponent label={docLang.measurementMaterial}
                                                  value={document.srMeasurementData.buildMaterial}/>
                        }
                        {document.srMeasurementData.propulsion &&
                            <DocumentRowComponent label={docLang.measurementPropulsion}
                                                  value={document.srMeasurementData.propulsion}/>
                        }
                        {document.srMeasurementData.length &&
                            <DocumentRowComponent label={docLang.measurementLength}
                                                  value={formatNumber(document.srMeasurementData.length.value, 3) + " " + document.srMeasurementData.length.unit}/>
                        }
                        {document.srMeasurementData.width &&
                            <DocumentRowComponent label={docLang.measurementWidth}
                                                  value={formatNumber(document.srMeasurementData.width.value, 3) + " " + document.srMeasurementData.width.unit}/>
                        }
                        {document.srMeasurementData.depth &&
                            <DocumentRowComponent label={docLang.measurementDepth}
                                                  value={formatNumber(document.srMeasurementData.depth.value, 3) + " " + document.srMeasurementData.depth.unit}/>
                        }
                        {document.srMeasurementData.draught &&
                            <DocumentRowComponent label={docLang.measurementDraught}
                                                  value={formatNumber(document.srMeasurementData.draught.value, 3) + " " + document.srMeasurementData.draught.unit}/>
                        }
                        {document.srMeasurementData.overallLength &&
                            <DocumentRowComponent label={docLang.measurementOverallLength}
                                                  value={formatNumber(document.srMeasurementData.overallLength.value, 3) + " " + document.srMeasurementData.overallLength.unit}/>
                        }
                        {document.srMeasurementData.grossTonnage &&
                            <DocumentRowComponent label={docLang.measurementGrossTonnage}
                                                  value={formatNumber(document.srMeasurementData.grossTonnage, 3)}/>
                        }
                        {document.srMeasurementData.netTonnage &&
                            <DocumentRowComponent label={docLang.measurementNetTonnage}
                                                  value={formatNumber(document.srMeasurementData.netTonnage, 3)}/>
                        }
                    </>
                }
                {document.docIssuer &&
                    <DocumentRowComponent label={"Doc issue"} value={document.docIssuer}/>
                }
                {document.smcIssuer &&
                    <DocumentRowComponent label={"Smc issue"} value={document.smcIssuer}/>
                }
                {document.isscIssuer &&
                    <DocumentRowComponent label={"Issc issue"} value={document.isscIssuer}/>
                }
                {document.csrNumber &&
                    <DocumentRowComponent label={docLang.csrNumber} value={document.csrNumber.toString()}/>
                }
                {document.csrDate &&
                    <DocumentRowComponent label={docLang.csrDate} value={formatDate(document.csrDate)}/>
                }
                {document.previousRegister &&
                    <DocumentRowComponent label={docLang.previousRegister} value={document.previousRegister}/>
                }
                {document.tagNumber && (
                    getUpdatedKey("tagNumber")
                        ? <DocumentRowComponent
                            label={docLang.tagNumber}
                            value={document.tagNumber}
                            changedDate={formatDate(getUpdatedKey("tagNumber")?.changedDate)}
                            anchorKey={getUpdatedKey("tagNumber")?.journalKey + document.documentTypeClass}
                            handleNavigateToHistoricalJournal={handleNavigateToHistoricalJournal}
                            isConferred={getUpdatedKey("vesselName")?.isConferred}/>
                        : <DocumentRowComponent label={docLang.tagNumber} value={document.tagNumber}/>
                )}
                {document.amounts?.map((amount) =>
                    <DocumentRowComponent key={amount.toString()} label={docLang.amount}
                                          value={amount.currency + " " + formatNumber(amount.amount, 2)}/>
                )}
                {document.endorsedByJournalKey &&
                    <DocumentRowComponent label={docLang.endorsedBy} value={document.endorsedByJournalKey}/>
                }
                {document.csrIsMandatory &&
                    <DocumentRowComponent label={docLang.CsrMandatory}
                                          value={document.csrIsMandatory ? docLang.yes : docLang.no}/>
                }
                {document.memo &&
                    <DocumentRowComponent label={docLang.memo} value={document.memo.toString()}/>
                }
                {document.deletedToCountry &&
                    <DocumentRowComponent label={docLang.deletedToCountry}
                                          value={document.deletedToCountry.toString()}/>
                }
                {document.deletedCause &&
                    <DocumentRowComponent label={docLang.deletedCause} value={document.deletedCause.toString()}/>
                }
                {document.documentTypeClass === "ET" && document.identificationNumber &&
                    <DocumentRowComponent label={docLang.identificationNumber} value={document.identificationNumber}/>
                }
                {document.isConferred === false &&
                    <DocumentRowComponent label={docLang.isConferred}
                                          value={document.isConferred ? docLang.yes : docLang.no}/>
                }
            </DocumentBody>
        </Document>
    )
}

interface DocumentRowProps {
    label: string;
    value?: string;
    changedDate?: string;
    anchorKey?: string;
    handleNavigateToHistoricalJournal?: (event: any, anchorKey: string) => void;
    isConferred?: boolean;
}

const DocumentRowComponent = ({
                                  label,
                                  value,
                                  changedDate,
                                  anchorKey,
                                  handleNavigateToHistoricalJournal,
                                  isConferred
                              }: DocumentRowProps) => {
    const docLang = getTranslations().vesselDocument;
    
    return (<>
        <Row className={changedDate ? "updated" : ""}>
            <Term>{label}</Term>
            <Description>
                {value?.split("\n").map((substring, index) => (<span key={index}>{substring}</span>))}
                {changedDate && anchorKey &&
                    <Tag type="primary" onClick={(event: any) => handleNavigateToHistoricalJournal?.(event, anchorKey)}>
                        {docLang.updated} {changedDate}
                    </Tag>}
            </Description>
        </Row>
        {isConferred === false && (
        <Row className={"isConferred"}>
            <Term>{docLang.isConferred}</Term>
            <Description>{docLang.no}</Description>
        </Row>)}
        </>)
}

const DocumentBody = styled.dl`
    container-name: --document-body;
    container-type: inline-size;
    font-size: 2rem;
`

const Row = styled.div`
    margin-bottom: 1.5rem;

    @container --document-body (min-width: 420px) {
        display: flex;
        margin-bottom: 2rem;
    }

    &.updated {
        background-color: #F8FAFB;
        border-radius: 4px;
        margin-bottom: 2rem;
        margin-left: -2rem;
        margin-top: 1.5rem;
        padding: 1.5rem 2rem 2rem;

        @container --document-body (min-width: 420px) {
            padding: 1.5rem;
            padding-left: 2rem;
        }

        @media print {
            background-color: transparent;
            margin-left: 0;
            padding: 0;
        }

        dd {
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem 4rem;
            justify-content: space-between;
        }
    }
    
    &.isConferred {
        background-color: #F8FAFB;
        border-radius: 4px;
        margin-left: -2rem;
        margin-top: -2.25rem;
        padding: 1.5rem 2rem 2rem 3rem;

        dt {
            border-left: 1px solid ${styles.colors.border};
            margin-bottom: 0;
            margin-left: 0;
            padding-bottom: 0.5rem;
            padding-left: 3rem;
        }
        
        dd {
            border-left: 1px solid ${styles.colors.border};
            margin-left: 0;
            padding-left: 7rem;
        }
        
        @container --document-body (min-width: 420px) {
            padding: 1.5rem;
            padding-left: 3rem;

            dt {
                width: 26rem;
            }
            
            dd {
                border-left: 0;
                padding-left: 0;
            }
        }

        @media print {
            background-color: transparent;
            
            dt {
                border-left-color: #aaaaaa;
                width: 23rem;
            }

            dd {
                border-left-color: #aaaaaa;
            }
        }
    }

    @media print {
        margin-bottom: 1rem;
    }
`

const Term = styled.dt`
    font-family: ${styles.fonts.stfMedium};
    color: ${styles.colors.primary};
    margin-bottom: 0.5rem;

    @container --document-body (min-width: 420px) {
        flex-shrink: 0;
        margin-bottom: 0;
        width: 27rem;
    }

    @media print {
        color: #000000;
        width: 24rem;
    }
`

const Description = styled.dd`
    margin-left: 4rem;

    @container --document-body (min-width: 420px) {
        flex-grow: 1;
        margin-left: 0;
    }

    &.indented {
        border-left: 1px solid ${styles.colors.border};
        margin-bottom: -1.5rem;
        margin-left: 1rem;
        padding-bottom: 1.5rem;
        padding-left: 3rem;
    }

    @container --document-body (min-width: 420px) {
        &.indented {
            border-left: none;
            margin: 0;
            padding: 0;
        }
    }

    > span {
        display: block;
    }
`

const Sub = styled.div`
    border-left: 1px solid ${styles.colors.border};
    margin-left: 1rem;
    padding-left: 3rem;

    @container --document-body (min-width: 420px) {
        dt {
            width: 23rem;
        }
    }

    @media print {
        border-left-color: #aaaaaa;
        margin-bottom: 1rem;

        dt {
            width: 20rem;
        }
    }
`

export const VesselDocument = VesselDocumentComponent;