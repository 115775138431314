import { FilesIcon } from "@navikt/aksel-icons";
import { styles } from "@sdir/sds";
import styled from "styled-components"

export interface MetadataListType {
    label: string;
    value: any;
} 

interface MetadataListProps {
    list: MetadataListType[]
}

const copyToClipBoard = function(textToCopy: string) {
    navigator.clipboard.writeText(textToCopy);
    alert(`${textToCopy} er kopiert 🎉`);
}

const MetadataListComponent = ({list}: MetadataListProps) => {
    return (
        <Wrapper>
            <dl>
                {list.map(item => (
                    <div key={item.label + item.value}>
                        <dt>{item.label}</dt>
                        <dd>
                            <span>{item.value ?? "-"}</span>
                            <button title={`Kopier ${item.label.toLowerCase()} til utklippstavlen`} onClick={() => copyToClipBoard(item.value)}>
                                <span className="u-hidden">Kopier {item.label.toLowerCase()} til utklippstavlen</span>
                                <FilesIcon />
                            </button>
                        </dd>
                    </div>
                ))}
            </dl>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    margin-top: 2rem;
    dl {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem 4rem;
    }

    dt {
        font-family: ${styles.fonts.stfMedium};
        color: ${styles.colors.font.text85};
        font-size: 1.75rem;
    }

    dd {
        font-size: 2.5rem;
        display: flex;
        align-items: center;
        gap: .5rem;
        &:hover {
            button {
                opacity: 1;
            }
        }
    }

    button {
        border: 0;
        background-color: transparent;
        font-size: 2.5rem;
        line-height: 1;
        opacity: 0;
        transition: opacity .15s ease;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: .5rem;
        border-radius: .5rem;
        
        &:focus-visible, &:focus {
            opacity: 1;
        }

        &:hover {
            background-color: ${styles.colors.primary9};
        }

        svg {
            flex-shrink: 0;
            color: ${styles.colors.font.text85};
        }
    }
`

export const MetadataList = MetadataListComponent;