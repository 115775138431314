import { styles } from "@sdir/sds";
import styled from "styled-components";
import { AnchorMenu } from "ui/AnchorMenu";
import {Grid, GridColumn} from "./VesselPage";
import {SrDocument} from "../../models/Models";
import {VesselDocument} from "../../ui/VesselDocument/VesselDocument";
import {useEffect} from "react";
import {getTranslations} from "../../helpers/Helpers";
import {DocumentGroup} from "../../ui/DocumentGroup/DocumentGroup";

interface PresentRecordProps {
    documents: SrDocument[];
    historicalRefs: any;
    currentHistoricalDocument: string;
}

interface GroupedDocuments {
    etablering: SrDocument[];
    teknisk: SrDocument[];
    heftelser: SrDocument[];
    hjemmel: SrDocument[];
}



const formatHistoricalDocumentGroup = function (documents : SrDocument[]) {
    const regularDocuments = documents
        .filter(document => !document.endorsedByJournalKey)
        .sort((a, b) => Date.parse(a.journalDate) - Date.parse(b.journalDate));

    const endorsementDocuments = documents
        .filter(document => document.endorsedByJournalKey)
        .sort((a, b) => new Date(a.journalDate) > new Date(b.journalDate) ? -1 : 1); //Reverse sort by date

    endorsementDocuments.forEach(endorsementDocument => {
        const documentBeingEndorsed = regularDocuments
            .find(document => {
                return document.journalKey.slice(4) === endorsementDocument.endorsedByJournalKey?.split("/")[0]
            });
        if (!documentBeingEndorsed)
            return;

        const documentBeingEndorsedIndex = regularDocuments.indexOf(documentBeingEndorsed);
        regularDocuments.splice(documentBeingEndorsedIndex + 1, 0, endorsementDocument);
    });
    return regularDocuments;
}

const HistoricalRecordsComponent = ({documents, historicalRefs, currentHistoricalDocument} : PresentRecordProps) => {
    const recordLang = getTranslations().record;

    const anchornames = [
        recordLang.establishment,
        recordLang.technical,
        recordLang.ownership,
        recordLang.encumberance
    ]
    
    const groupedDocuments : GroupedDocuments = {
        etablering: formatHistoricalDocumentGroup(documents.filter(document => document.documentTypeClass === "ET")),
        teknisk: formatHistoricalDocumentGroup(documents.filter(document => document.documentTypeClass === "TE")),
        hjemmel: formatHistoricalDocumentGroup(documents.filter(document => document.documentTypeClass === "HJ")),
        heftelser: formatHistoricalDocumentGroup(documents.filter(document => document.documentTypeClass === "HE"))
    };

    const anchorListItems = []
    groupedDocuments.etablering.length > 0 && 
        anchorListItems.push({name: recordLang.establishment, anchorKey: groupedDocuments.etablering[0].journalKey + "ET"});
    groupedDocuments.teknisk.length > 0 && 
        anchorListItems.push({name: recordLang.technical, anchorKey: groupedDocuments.teknisk[0].journalKey + "TE"});
    groupedDocuments.hjemmel.length > 0 &&
        anchorListItems.push({name: recordLang.ownership, anchorKey: groupedDocuments.hjemmel[0].journalKey + "HJ"});
    groupedDocuments.heftelser.length > 0 && 
        anchorListItems.push({name: recordLang.encumberance, anchorKey: groupedDocuments.heftelser[0].journalKey + "HE"});

    useEffect(() => {
        if (currentHistoricalDocument !== "") {
            window.scrollTo({
                top: historicalRefs.current[currentHistoricalDocument].getBoundingClientRect().top + window.scrollY,
                behavior: "smooth"
            });
        }
    }, [currentHistoricalDocument])
    
    return (
        <Grid>
            <GridColumn>
                <AnchorMenu
                    anchorListItems={anchorListItems}
                    docRefs={historicalRefs}
                />
            </GridColumn>
            <GridColumn>
                {
                    anchornames.map((anchorname, index) =>
                        Object.values(groupedDocuments)[index].length > 0 &&
                        <Timeline key={anchorname}>
                            <DocumentGroup title={anchorname}>
                            {
                                Object.values(groupedDocuments)[index].map((document : SrDocument) =>
                                    <li className={document.endorsedByJournalKey ? "endorsement" : ""} key={document.journalKey + '_' + document.documentType} ref={(el : any) => historicalRefs.current[document.journalKey + document.documentTypeClass] = el}>
                                        <VesselDocument document={document}></VesselDocument>
                                    </li>
                                )
                            }
                            </DocumentGroup>
                        </Timeline>
                    )
                }
            </GridColumn>
        </Grid>
    )
}

const Timeline = styled.div`
  
  @media screen and (min-width: 1180px) {
    padding-left: 5.5rem;

    li {
      position: relative;

      &::before {
        background-color: ${styles.colors.border};
        content: "";
        display: block;
        height: 100%;
        left: -4rem;
        position: absolute;
        top: 3.5rem;
        width: 2px;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: -5.5rem;
        top: 2.5rem;
        width: 2.625rem;
        height: 2.625rem;
        border: 2px solid ${styles.colors.primary2};
        border-radius: 50%;
        background-color: ${styles.colors.background.white};
      }

      &.endorsement::after {
          top: 0.375rem;
      }

      &:first-child {
        &::after {
          border-radius: 0;
          height: 0.5rem;
          top: 3.25rem;
        }
      }

      &:last-child {
        &::before {
          content: none;
        }
      }
    }
  }
`

export const HistoricalRecord = HistoricalRecordsComponent; 