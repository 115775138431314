import { styles } from "@sdir/sds";
import React from "react";
import styled from "styled-components";
import {formatDate, formatDateWithTime} from "../../helpers/Helpers";

interface TitleBannerProps {
    title: string;
    children?: React.ReactNode;
}

const TitleBannerComponent = ({ title, children }: TitleBannerProps) => {
    return (
        <Banner>
            <PageTitle>{title}</PageTitle>
            <PrintDate>Print: {formatDateWithTime(new Date())}</PrintDate>
            { children }
        </Banner>
    )
}

const PageTitle = styled.h1`
    font-size: ${styles.font.size.xl};
    font-family: ${styles.fonts.stfSemibold};
    line-height: 1.125;
    margin: 0;
    
    @media (min-width: 800px) {
        font-size: ${styles.font.size.xxxl};
    }
`

const PrintDate = styled.p`
    display: none;
    margin-left: auto;
    margin-top: 1rem;
    
    @media print {
        display: block;
    }
`

const Banner = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    padding-bottom: 3rem;
    border-bottom: .0125rem solid ${styles.colors.border};
    margin-top: 3rem;
    margin-bottom: 6rem;
  
    @media print {
      border-color: #aaaaaa;
      margin-bottom: 0;
      padding-bottom: 1rem;
    }
    `


export const TitleBanner = TitleBannerComponent;