import {SdirRadioButtonGroup, styles} from "@sdir/sds";
import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {ChevronDownIcon} from '@navikt/aksel-icons';
import {getTranslations} from "../../helpers/Helpers";
import {SimpleVesselDTO} from "../../models/Models";
import {Register} from "../../routes/pages";

interface FilterProps {
    toggleText: string;
    searchQuery: string;
    searchResult: SimpleVesselDTO[];
    updateFilteredResult: (filteredResult: SimpleVesselDTO[]) => void;
    register: Register
}

const FilterComponent = ({toggleText, searchQuery, searchResult, updateFilteredResult, register}: FilterProps) => {
    const filterLang = getTranslations().filter;
    const [isVisible, setIsVisible] = useState(window.innerWidth > 1000);
    const [dataRadioValue, setDataRadioValue] = useState('all');
    const [registerRadioValue, setRegisterRadioValue] = useState('all');
    const [statusRadioValue, setStatusRadioValue] = useState('all');

    useEffect(() => {
        let tempFilteredSearchResults: SimpleVesselDTO[] = searchResult ?? [];

        switch (dataRadioValue) {
            case "all":
                break;
            case "callSign":
                tempFilteredSearchResults = tempFilteredSearchResults.filter(vessel =>
                    vessel.callSign?.toLowerCase().includes(searchQuery.toLowerCase())
                );
                break;
            case "vesselName":
                tempFilteredSearchResults = tempFilteredSearchResults.filter(vessel =>
                    vessel.name?.toLowerCase().includes(searchQuery.toLowerCase())
                );
                break;
            case "imo":
                tempFilteredSearchResults = tempFilteredSearchResults.filter(vessel =>
                    vessel.imo?.toString().includes(searchQuery.toLowerCase())
                );
                break;
            case "shipyard":
                tempFilteredSearchResults = tempFilteredSearchResults.filter(vessel =>
                    vessel.shipyard?.toLowerCase().includes(searchQuery.toLowerCase())
                );
                break;
            case "constructionNumber":
                tempFilteredSearchResults = tempFilteredSearchResults.filter(vessel =>
                    vessel.constructionNumber?.toString().includes(searchQuery.toLowerCase())
                );
                break;
            case "legalEntityName":
                tempFilteredSearchResults = tempFilteredSearchResults.filter(vessel => {
                    const vesselsContainingEntityName = vessel.roles?.filter(role =>
                        role.legalEntity?.name?.toLowerCase().includes(searchQuery.toLowerCase())
                        || role.coLegalEntity?.name?.toLowerCase().includes(searchQuery.toLowerCase())
                    );
                    return vesselsContainingEntityName && vesselsContainingEntityName.length > 0;
                });
                break;
            case "legalEntityId":
                tempFilteredSearchResults = tempFilteredSearchResults.filter(vessel => {
                    const vesselsContainingEntityId = vessel.roles?.filter(role =>
                        role.legalEntity?.entityId?.toLowerCase().includes(searchQuery.toLowerCase())
                        || role.coLegalEntity?.entityId?.toLowerCase().includes(searchQuery.toLowerCase())
                    );
                    return vesselsContainingEntityId && vesselsContainingEntityId.length > 0;
                });
                break;
            default:
                break;
        }

        switch (statusRadioValue) {
            case "active":
                tempFilteredSearchResults = tempFilteredSearchResults.filter(vessel =>
                    vessel.status === "Aktivt" || vessel.status === "Active"
                );
                break;
            case "deleted":
                tempFilteredSearchResults = tempFilteredSearchResults.filter(vessel =>
                    vessel.status === "Slettet" || vessel.status === "Deleted"
                );
                break;
            default:
                break;
        }

        switch (registerRadioValue) {
            case "all":
                break;
            case "nis":
                tempFilteredSearchResults = tempFilteredSearchResults.filter(vessel =>
                    vessel.register === "Norwegian International Ship Register" || vessel.register === "Norsk Internasjonalt Skipsregister" || vessel.register === "NIS"
                );
                break;
            case "nor":
                tempFilteredSearchResults = tempFilteredSearchResults.filter(vessel =>
                    vessel.register === "Norwegian Ordinary Ship Register" || vessel.register === "Norsk Ordinært Skipsregister" || vessel.register === "NOR"
                );
                break;
            default:
                break;
        }

        updateFilteredResult(tempFilteredSearchResults);
    }, [dataRadioValue, statusRadioValue, registerRadioValue, searchResult])

    const handleToggle = () => {
        setIsVisible(!isVisible);
    }

    return (
        <Wrapper>
            <Toggle onClick={handleToggle} className={isVisible ? "is-open" : ""}>
                <span>{toggleText}</span>
                <ChevronDownIcon/>
            </Toggle>
            <Card className={isVisible ? "is-open" : ""}>
                <Groups>
                    {register === "nisnor" ?
                        <><Fieldset>
                            <legend>{filterLang.dataField}</legend>
                            <SdirRadioButtonGroup
                                as={Group}
                                options={[{
                                    label: filterLang.optionAllDataFields,
                                    value: "all"
                                }, {
                                    label: filterLang.optionCallSign,
                                    value: "callSign"
                                }, {
                                    label: filterLang.optionVesselName,
                                    value: "vesselName"
                                }, {
                                    label: "IMO nr.",
                                    value: "imo"
                                }, {
                                    label: filterLang.optionLegalEntityName,
                                    value: "legalEntityName"
                                }, {
                                    label: filterLang.optionLegalEntityId,
                                    value: "legalEntityId"
                                }]}
                                value={dataRadioValue}
                                name="{filterLang.dataField}"
                                onChange={(e) => setDataRadioValue(e.target.value)}
                            />
                        </Fieldset>
                        <Fieldset>
                            <legend>Register</legend>
                            <SdirRadioButtonGroup
                                as={Group}
                                options={[{label: filterLang.optionAllRegisters, value: "all"}, {
                                    label: "NIS",
                                    value: "nis"
                                }, {label: "NOR", value: "nor"}]}
                                value={registerRadioValue}
                                name="Register"
                                onChange={(e) => setRegisterRadioValue(e.target.value)}
                            />
                        </Fieldset>
                        <Fieldset>
                            <legend>Status</legend>
                            <SdirRadioButtonGroup
                                as={Group}
                                options={[{
                                    label: filterLang.optionAllStatuses,
                                    value: "all"
                                }, {
                                    label: filterLang.optionActive,
                                    value: "active"
                                }, {
                                    label: filterLang.optionDeleted,
                                    value: "deleted"
                                }]}
                                value={statusRadioValue}
                                name="Status"
                                onChange={(e) => setStatusRadioValue(e.target.value)}
                            />
                        </Fieldset></>
                        : register === "bygg" ?
                        <><Fieldset>
                            <legend>{filterLang.dataField}</legend>
                            <SdirRadioButtonGroup
                                as={Group}
                                options={[{
                                    label: filterLang.optionAllDataFields,
                                    value: "all"
                                }, {
                                    label: filterLang.optionShipyard,
                                    value: "shipyard"
                                }, {
                                    label: filterLang.optionConstructionNumber,
                                    value: "constructionNumber"
                                }, {
                                    label: filterLang.optionLegalEntityName,
                                    value: "legalEntityName"
                                }, {
                                    label: filterLang.optionLegalEntityId,
                                    value: "legalEntityId"
                                }]}
                                value={dataRadioValue}
                                name={filterLang.dataField}
                                onChange={(e) => setDataRadioValue(e.target.value)}
                            />
                        </Fieldset>
                        <Fieldset>
                            <legend>Status</legend>
                            <SdirRadioButtonGroup
                                as={Group}
                                options={[{
                                    label: filterLang.optionAllStatuses,
                                    value: "all"
                                }, {
                                    label: filterLang.optionActive,
                                    value: "active"
                                }, {
                                    label: filterLang.optionDeleted,
                                    value: "deleted"
                                }]}
                                value={statusRadioValue}
                                name="Status"
                                onChange={(e) => setStatusRadioValue(e.target.value)}
                            />
                        </Fieldset></>
                        : 
                        <Fieldset>
                            <legend>Register</legend>
                            <SdirRadioButtonGroup
                                as={Group}
                                options={[{
                                    label: filterLang.optionAllRegisters,
                                    value: "all"
                                }, {
                                    label: "NIS",
                                    value: "nis"
                                }, {
                                    label: "NOR",
                                    value: "nor"
                                }]}
                                value={registerRadioValue}
                                name="Register"
                                onChange={(e) => setRegisterRadioValue(e.target.value)}
                            />
                        </Fieldset>
                    }
                </Groups>
            </Card>
        </Wrapper>
    )
}

const Wrapper = styled.div``;

const Toggle = styled.button`
    align-items: center;
    color: ${styles.colors.font.text};
    cursor: pointer;
    background-color: ${styles.colors.background.white};
    border: 1px solid ${styles.colors.border};
    border-radius: .75rem;
    display: flex;
    font: inherit;
    gap: 2rem;
    justify-content: space-between;
    margin-bottom: .75rem;
    padding: .75em 1em;
    text-align: left;
    transition: border-color .15s ease;
    width: 100%;

    svg {
        font-size: 1.5em;
    }

    &.is-open {
        svg {
            transform: rotate(180deg);
        }
    }

    &:hover {
        border-color: ${styles.colors.primary2};
    }
`

const Card = styled.div`
    background-color: ${styles.colors.background.white};
    border-radius: ${styles.card.borderRadius};
    border: 1px solid ${styles.colors.border};
    display: none;
    @media (min-width: 800px) {
        display: block;
    }

    &.is-open {
        display: block;
    }

    &:not(.is-open) {
        display: none;
    }
`

const Groups = styled.div`
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
`

const Fieldset = styled.fieldset`
    padding: 0;
    border: 0;
    margin: 0;

    legend {
        font-size: 1.75rem;
        text-transform: uppercase;
        font-family: ${styles.fonts.stfMedium};
        color: ${styles.colors.font.text85};
        letter-spacing: 0.05em;
        margin-bottom: .75em;
    }
`

const Group = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

export const Filter = FilterComponent;