import {Language} from "../routes/MainRoutes";
import lang from "../lang/language.json";

export const otherLanguageURL = function () : string {
    
    if (window.location.href.includes("/en")) {
        document.documentElement.setAttribute("lang", "en");
        return window.location.pathname
            .replace("/en", "").concat(window.location.hash.split("/en/")[1]);
    } else {
        document.documentElement.setAttribute("lang", "no");
        return "/en".concat(window.location.pathname).concat(window.location.hash ? window.location.hash.split("/")[1] : "");
    }
}

export const getLanguageFromURL = function () : Language {
    return window.location.href.includes("/en") ? "en" : "no";
}

export const getTranslations = function () {
    return lang[getLanguageFromURL()];
}

export const formatDate = function (oldDateString : string | undefined) {
    if (!oldDateString) return "";
    
    let date = new Date(oldDateString);
    
    if (date.toString() === "Invalid Date") {
        const isoDateAsArray = oldDateString.split("."); // Safari  browser workaround
        date = new Date(parseInt(isoDateAsArray[0]), parseInt(isoDateAsArray[1]) - 1, parseInt(isoDateAsArray[2]));
    }
    
    if (getLanguageFromURL() === "en") {
        return date.getFullYear() + "." + ("0"+(date.getMonth()+1)).slice(-2) + "." + ("0" + date.getDate()).slice(-2)
    }
    
    return ("0" + date.getDate()).slice(-2) + "." + ("0"+(date.getMonth()+1)).slice(-2) + "." + date.getFullYear()
}

export const formatDateWithTime = function (date : Date) {

    if (getLanguageFromURL() === "en") {
        return ( "0" + date.getHours()).slice(-2) + ":" + ( "0" + date.getMinutes()).slice(-2) + "   "  + date.getFullYear() + "." + ("0"+(date.getMonth()+1)).slice(-2) + "." + ( "0" + date.getDate()).slice(-2)
    }
    
    return ( "0" + date.getHours()).slice(-2) + ":" + ( "0" + date.getMinutes()).slice(-2) + "   " + ( "0" + date.getDate()).slice(-2) + "." + ("0"+(date.getMonth()+1)).slice(-2) + "." + date.getFullYear()
}

export const formatNumber = function (number : number | undefined, decimals : number) {
    if (!number) return "";

    let localeString = number.toLocaleString('NO');
    let commaIndex = localeString.indexOf(",");

    if (commaIndex === -1) {
        localeString += ","
        commaIndex = localeString.indexOf(",");
    }

    localeString += "0".repeat(decimals);
    
    return localeString.slice(0, commaIndex + (decimals + 1));
}