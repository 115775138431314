import {CsrVesselDTO, ReservedVesselDTO, SimpleCsrVesselDTO, SimpleVesselDTO, VesselDTO} from "../models/Models";
import {getLanguageFromURL} from "../helpers/Helpers";

const version = "v1/"
const apiUrl = process.env.REACT_APP_ENVIRONMENT === "local" 
    ? process.env.REACT_APP_API_URL + version
    : process.env.REACT_APP_API_URL + "los-vesselsearch-internal/" + version;

export const getVesselsNisNor = async function (searchQuery : string) : Promise<SimpleVesselDTO[] | undefined> {
    
    const searchParams = { searchText: searchQuery, language: getLanguageFromURL()};

    try {
        const response = await fetch(apiUrl + 
            'vessel-list-nis-nor?' + 
            new URLSearchParams({...searchParams})
        );

        if (!response.ok) {
            console.error("Error fetching data");
        }

        return await response.json();
        
    } catch (err) {
        console.error("Error fetching data: " + err);
    }
};

export const getVesselsBygg = async function (searchQuery : string) : Promise<SimpleVesselDTO[] | undefined> {

    const searchParams = { searchText: searchQuery, language: getLanguageFromURL()};

    try {
        const response = await fetch(apiUrl +
            'vessel-list-bygg?' +
            new URLSearchParams({...searchParams})
        );

        if (!response.ok) {
            console.error("Error fetching data");
        }

        return await response.json();

    } catch (_) {
        console.error("Error fetching data");
    }
};

export const getSimpleCsrVessel = async function (searchQuery : string) : Promise<SimpleCsrVesselDTO | undefined> {

    const searchParams = { searchText: searchQuery, language: getLanguageFromURL()};

    try {
        const response = await fetch(apiUrl +
            'csr?' +
            new URLSearchParams({...searchParams})
        );

        if (!response.ok) {
            console.error("Error fetching data");
        } else if (response.status === 204) {
            return undefined;
        }

        return await response.json();

    } catch (e) {
        console.error("Error fetching data:", e);
    }
};

export const getReservedVessels = async function (searchQuery : string) : Promise<ReservedVesselDTO[] | undefined> {

    const searchParams = { searchText: searchQuery, language: getLanguageFromURL()};

    try {
        const response = await fetch(apiUrl +
            'reserved?' +
            new URLSearchParams({...searchParams})
        );

        if (!response.ok) {
            console.error("Error fetching data");
        }

        return await response.json();

    } catch (_) {
        console.error("Error fetching data");
    }
};

export const getVessel = async function (searchQuery : string) : Promise<VesselDTO | undefined> {

    const searchParams = { language: getLanguageFromURL() };
    
    try {
        const response = await fetch(
            apiUrl + 
            'vessel-data/' + 
            searchQuery + 
            '?' +
            new URLSearchParams({...searchParams})
        );

        if (!response.ok) {
            console.error("Error fetching data");
        }

        return await response.json();

    } catch (_) {
        console.error("Error fetching data");
    }
};

export const getHistoricalVessel = async function (searchQuery : string) : Promise<VesselDTO | undefined> {

    const searchParams = { language: getLanguageFromURL() };
    
    try {
        const response = await fetch(apiUrl + 
            'historical-vessel-data/' + 
            searchQuery +
            '?' +
            new URLSearchParams({...searchParams})
        );

        if (!response.ok) {
            console.error("Error fetching data");
        }

        return await response.json();

    } catch (_) {
        console.error("Error fetching data");
    }
};

export const getCsrVessel = async function (searchQuery : string) : Promise<CsrVesselDTO | undefined> {

    const searchParams = { language: getLanguageFromURL() };

    try {
        const response = await fetch(
            apiUrl +
            'csr/' +
            searchQuery +
            '?' +
            new URLSearchParams({...searchParams})
        );

        if (!response.ok) {
            console.error("Error fetching data");
        }

        return await response.json();

    } catch (_) {
        console.error("Error fetching data");
    }
};