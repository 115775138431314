import { HashRouter, Route, Routes } from "react-router-dom";
import { StartPage, VesselPage, NotFoundPage } from "./pages";
import { AppLayout } from "layouts";

export type Language = "no" | "en";

export const MainRoutes = () => {
    
    return (
        <HashRouter>
            <Routes>
                <Route path="" element={<AppLayout />}>
                    <Route path="*" element={<NotFoundPage />} />
                    <Route index element={<StartPage />} />
                    <Route path="vessel" element={<VesselPage />} />
                </Route>
                <Route path="en" element={<AppLayout />}>
                    <Route path="*" element={<NotFoundPage />} />
                    <Route index element={<StartPage />} />
                    <Route path="vessel" element={<VesselPage />} />
                </Route>
            </Routes>
        </HashRouter>
    );
};