import { SdirButton, Select, SelectOption, styles } from "@sdir/sds";
import styled from "styled-components";
import {ChangeEvent} from "react";

interface SearchBarProps {
    searchPlaceholder?: string;
    searchLabel?: string;
    dropdownLabel?: string;
    dropdownOptions?: SelectOption[];
    submitText?: string;
    onSubmit: () => Promise<void>;
    setSearchQuery: (searchQuery : string) => void;
    searchQuery: string;
    handleOptionSelect: any;
    registerOption: any;
}

const SearchBarComponent = ({searchPlaceholder, searchLabel, dropdownLabel, dropdownOptions, submitText, onSubmit, setSearchQuery, searchQuery, handleOptionSelect, registerOption}: SearchBarProps) => {

    function handleInputChange(e : ChangeEvent<HTMLInputElement>) {
        setSearchQuery(e.target.value);
    }

    async function handleKeyDown(e : any) {
        if (e.key === "Enter") {
            await onSubmit();
        }
    }

    return (
        <Search>
            <Group>
                <Label htmlFor="searchKeyword">{ searchLabel }</Label>
                <Input id="searchKeyword" placeholder={ searchPlaceholder } onChange={handleInputChange} onKeyDown={handleKeyDown} value={searchQuery}></Input>
            </Group>
            <Group>
                <LabelSpan id={"registerSelectLabel"}>{ dropdownLabel }</LabelSpan>
                <Dropdown>
                    <Select
                        aria-labelledby={"registerSelectLabel"}
                        options={dropdownOptions}
                        handleChange={(_, option) => handleOptionSelect(option)}
                        defaultVal={registerOption.name}
                    />
                </Dropdown>
            </Group>
            <Action>
                <SdirButton type="primary" onClick={onSubmit}>{ submitText }</SdirButton>
            </Action>
        </Search>
    )
}

const Search = styled.div`
    display: grid;
    align-items: flex-end;
    gap: 2.5rem 1.5rem;
    @media (min-width: 800px) {
        gap: 1.5rem;
        grid-template-columns: 2fr 1fr auto;
    }
`

const Group = styled.div`
    display: flex;
    flex-direction: column;
`


const Label = styled.label`
    font-family: ${styles.fonts.stfMedium};
    padding-bottom: 0.5rem;
`

const LabelSpan = styled.span`
    font-family: ${styles.fonts.stfMedium};
    padding-bottom: 0.5rem;
`

const Input = styled.input.attrs({ type: "text" })`
    border-radius: .5rem;
    background-color: ${styles.colors.background.white};
    border: 1px solid ${styles.colors.primary8};
    padding: 0 2rem;
    color: ${styles.colors.font.text};
    height: 6rem;
    
    &:hover {
        box-shadow: 0 0 3px #3D81AE;
    }
`

const Dropdown = styled.div`
    height: 100%;
`

const Action = styled.div`
`

export const SearchBar = SearchBarComponent;