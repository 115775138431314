import { styles } from "@sdir/sds"
import styled from "styled-components"
import {getTranslations} from "../../helpers/Helpers";


interface DocumentProps {
    journalNumber: string;
    date: string;
    title: string;
    isEndorsement: boolean;
    children: React.ReactNode;
}

const DocumentComponent = ({journalNumber, date, title, isEndorsement, children}: DocumentProps) => {
    const recordLang = getTranslations().record;
    
    return (
        <Wrapper className={isEndorsement ? "endorsement" : ""}>
            <div>
                <Label>{ recordLang.journalNumber }</Label>
                <span>{ journalNumber }</span>
            </div>
            <div>
                <Label>{ recordLang.date }</Label>
                <span>{ date }</span>
            </div>
            <h3>{ title }</h3>
            <Content>
                { children }
            </Content>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    align-items: center;
    border-top: 1px solid ${styles.colors.border};
    display: grid;
    grid-template-columns: 13rem 1fr;
    padding: 3rem 2rem 4rem;
    row-gap: 1rem;
  
    li:first-child & {
        border-top: none;
    }
  
    &.endorsement {
        border-top: none;
        padding-top: 0;
    }
    
    @media screen and (min-width: 800px) {
        grid-template-columns: 13rem 28rem 1fr;
        padding: 2rem 3rem;
    }
  
    @media print {
        border-top-color: #aaaaaa;
        grid-template-columns: 13rem 20rem 1fr;
        padding: 1rem 0;
    }
  
  h3 {
      font-family: ${styles.fonts.stfMedium};
      font-size: 2.5rem;
      grid-column: 2;
      margin: 1rem 0 0;

      @media print, screen and (min-width: 800px) {
          grid-column: 3;
          margin-top: 0;
      }
  }
`

const Label = styled.span`
    color: ${styles.colors.primary};
    display: block;
    font-family: ${styles.fonts.stfMedium};
    font-size: 2rem;
    line-height: 1.5;
  
    @media (min-width: 800px) {
      clip: rect(0 0 0 0);
      clip-path: inset(100%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  
    @media print {
      display: none;
    }
`

const Content = styled.div`
    grid-column: 2;

    @media print, screen and (min-width: 800px) {
        grid-column: 3;
    }
`

export const Document = DocumentComponent;