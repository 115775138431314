import { ArrowRightIcon } from "@navikt/aksel-icons";
import { styles } from "@sdir/sds";
import styled from "styled-components";
import {SimpleVesselDTO} from "../../models/Models";
import { getTranslations} from "../../helpers/Helpers";
import {Link} from "react-router-dom";

interface ShipyardResultItemProps {
    vessel : SimpleVesselDTO;
    register : string;
    searchString: string;
}

const ShipyardResultItemComponent = ({vessel, register, searchString}: ShipyardResultItemProps) => {
    const resultItemLang = getTranslations().shipyardResultItem;

    return (
        <Card>
            <div>
                <Link to={`vessel?vesselId=${vessel.vesselId}&search=${searchString}&register=${register}`}>{vessel.shipyard ?? "-"}</Link>
                <ArrowRightIcon/>
            </div>
            <dl>
                <div>
                    <dt>{resultItemLang.buildId}</dt>
                    <dd>{vessel.constructionNumber ?? "-"}</dd>
                </div>
                <div>
                    <dt>Status</dt>
                    <dd>{vessel.status}</dd>
                </div>
            </dl>
        </Card>
    )
}

const Card = styled.li`
    background-color: ${styles.colors.background.white};
    border-radius: ${styles.card.borderRadius};
    border: 1px solid ${styles.colors.border};
    position: relative;
    transition: border-color .15s ease;

    &:has(a:hover) {
        border-color: ${styles.colors.primary2};
    }

    a {
        font-size: 2.5rem;
        font-family: ${styles.fonts.stfMedium};
        color: ${styles.colors.primary};
        
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }

    & > div {
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        gap: 2rem;
        justify-content: space-between;
        @container grid-column (min-width: 500px) {
            padding: 1.5rem 2.5rem;
        }

        svg {
            font-size: 1.5em;
            color: ${styles.colors.primary};
            flex-shrink: 0;
        }
    }
    
    dl {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        border-top: 1px solid ${styles.colors.border};
        padding: 1rem 2rem;
        gap: 1rem;
        @container grid-column (min-width: 500px) {
            padding: 1.5rem 2.5rem;
            gap: 1.5rem;
            grid-template-columns: repeat(4, 1fr);
        }

        div {
            display: flex;
            flex-direction: column;
            gap: .25rem;
            line-height: 1.25;
            padding-bottom: 1rem;
            padding-top: 1rem;
            
            @container grid-column (min-width: 500px) {
                border-right: 1px solid ${styles.colors.border};
                padding-right: 1rem;
                &:last-child {
                    border-right: 0;
                }
            }
            
            @container grid-column (max-width: 500px) {
                border-bottom: 1px solid ${styles.colors.border};
                &:nth-child(1n + 3) {
                    border-bottom: 0;
                }
            }
        }
    }
    
    dt {
        font-family: ${styles.fonts.stfMedium};
        font-size: 1.75rem;
        color: ${styles.colors.font.text85};
    }
`

export const ShipyardResultItem = ShipyardResultItemComponent;